import React from 'react';

import './Notes.scss';

// https://codepen.io/MaryG/pen/wJMMdw

const Notes = () => {
	return (
		<div className="musical-notes">

			<div className="muzieknootjes">
				<div className="noot-1">
					&#9835; &#9833;
				</div>
				<div className="noot-2">
					&#9833;
				</div>
				<div className="noot-3">
					&#9839; &#9834;
				</div>
				<div className="noot-4">
					&#9834;
				</div>
			</div>
			
		</div>
	)
}


export default Notes;