import React from 'react';

import './Snowy.scss';

// https://codepen.io/_fbrz/pen/iqtlk

const Snowy = () => {
	return (
		<div className="snowy"></div>
	)
}


export default Snowy;