import React, { useEffect, useRef } from 'react';

import './AnalogClock.scss';

// https://cssanimation.rocks/clocks/

const AnalogClock = () => {

	const hourHand = useRef(null);
	const minHand = useRef(null);
	const secondHand = useRef(null);

	useEffect(() => {
		
		const timer = setInterval(setTime, 1000);

		// Init
		setTime();

		return () => {
			clearInterval(timer);
		}
	}, []);

	
	const setTime = () => {

		var date = new Date();
		var seconds = date.getSeconds();
		var minutes = date.getMinutes();
		var hours = date.getHours();

		var hands = {
			hours: (hours * 30) + (minutes / 2),
			min: (minutes * 6),
			secs: (seconds * 6)
		};

		hourHand.current.style.transform = `rotateZ(${hands.hours}deg)`;
		minHand.current.style.transform = `rotateZ(${hands.min}deg)`;
		secondHand.current.style.transform = `rotateZ(${hands.secs}deg)`;
	}

	

	return (
		<div className='AnalogClock' style={{height:'100%', width:'100%'}}>

			<div className="clock">
				<div className="hours-container">
					<div className="hours" ref={ hourHand } ></div>
				</div>
				<div className="minutes-container">
					<div className="minutes" ref={ minHand } ></div>
				</div>
				<div className="seconds-container">
					<div className="seconds" ref={ secondHand } ></div>
				</div>
			</div>

		</div>
	)
}


export default AnalogClock;